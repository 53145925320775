import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AboutMe from "./components/AboutMe";
import Post from "./components/Post";
import PhotoGallery from "./components/PhotoGallery";

function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path='*' element={<Navigate to='/post/AM' />} />
                <Route path="/post/:postName" element={<Post />} />
                <Route path="/pictures" element={<PhotoGallery />} />
            </Routes>
        </Router>
    );


}
export default AppRoutes;