import ImageGallery from "react-image-gallery";
import images from "../content/images.json";
import "react-image-gallery/styles/css/image-gallery.css"
function PhotoGallery() {
    return (
        <>
            <ImageGallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
            />
        </>
    );
}



export default PhotoGallery;