import styles from "./Title.module.css";


function Title() {
    return (
        <div className={`${styles.appTitle}`}>
            <h1>
                <a href="/">Ayushman Ray</a>
            </h1>
        </div>
    )
}

export default Title