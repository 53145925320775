import './App.css';
import AppRoutes from './AppRoutes';
import Title from './components/Title';
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  return (
    <>
      <Title />
      <AppRoutes />
    </>
  );
}

export default App;
