import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import styles from "./Markdown.module.css";

function MarkdownReader({ markdownFileName }: { markdownFileName: string }) {
    const [markdownContent, setMarkdownContent] = useState("");

    useEffect(() => {
        import(`../content/markdowns/${markdownFileName}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setMarkdownContent(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });

    return (
        <div className={`${styles.container}`} >
            <Markdown>
                {markdownContent}
            </Markdown>
        </div>
    );
}

export default MarkdownReader;