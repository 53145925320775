import { useParams } from "react-router-dom";
import MarkdownReader from "./MarkdownReader";

function Post() {
    var { postName } = useParams();
    return (
        <>
            <MarkdownReader markdownFileName={`${postName}.md`} />
        </>
    );
}

export default Post;